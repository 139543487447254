import {useState,useEffect,useRef,useContext,Fragment} from "react"
import {useNavigate} from "react-router-dom"
import {osName,osVersion,browserName,browserVersion,mobileVendor,mobileModel,deviceType} from "react-device-detect"
import Countdown from "react-countdown"
import GlobalContext from "../contexts/GlobalContext"
import KRGlue from "@lyracom/embedded-form-glue";
import useApi from "../hooks/useApi"
import useDialog from "../hooks/useDialog"
import useToast from "../hooks/useToast"
import {formatAmount} from "../hooks/Commons"
import Avatar from "../commons/Avatar"
import PaymentButton from "../commons/PaymentButton"
import PaymentTextbox from "../commons/PaymentTextbox"
import PaymentItem from "../commons/PaymentItem"
import PaymentIcon from "../commons/PaymentIcon";
import brandImg from "../assets/brand-inverse.svg"
import failImg from "../assets/fail.svg"
import fail2Img from "../assets/fail-2.svg"
import secureImg from "../assets/secure.svg"
import cvvImg from "../assets/cvv.svg"
import backImg from "../assets/back-3.svg"
import brand from "../assets/brand-inverse.svg"
import sparkles from "../assets/sparkles.svg"

export default function Payment({context,reference,event}){

    const [globalContext]=useContext(GlobalContext)
    const navigate=useNavigate()
    const requestApi=useApi()
    const {changeDialog,manageDialog}=useDialog()
    const {changeToast}=useToast()
    const [fail,setFail]=useState(false)
    const [payment,setPayment]=useState()
    const [stage,setStage]=useState("")
    const [amount,setAmount]=useState(0)
    const [userChange,setUserChange]=useState(false)
    const [error,setError]=useState("")
    const [success,setSuccess]=useState("")
    const [returning,setReturning]=useState("")
    const [transactionId,setTransactionId]=useState(0)
    const [savedUserId,setSavedUserId]=useState((localStorage.getItem("payment_user_id") && context!="webpos")?localStorage.getItem("payment_user_id"):0)

    const emailInput=useRef()
    const firstnameInput=useRef()
    const lastnameInput=useRef()
    const dniInput=useRef()
    const [emailVal,setEmailVal]=useState("")
    const [firstnameVal,setFirstnameVal]=useState("")
    const [lastnameVal,setLastnameVal]=useState("")
    const [dniVal,setDniVal]=useState("")

    const qrAmountInput=useRef()
    const qrDetailInput=useRef()
    const [qrAmountVal,setQrAmountVal]=useState("")
    const [qrDetailVal,setQrDetailVal]=useState("")

    const cbuInput=useRef()
    const cuitInput=useRef()
    const [cbuVal,setCbuVal]=useState("")
    const [cuitVal,setCuitVal]=useState("")

    const payzenForm=useRef()
    const [selectedCard,setSelectedCard]=useState({
        title:"Tarjetas aceptadas:",
        icon_type:"new-card",
        icon_reference:""
    })

    const [qrInteroperableData, setQrInteroperableData] = useState({encoded_qr: '', order_id: '', interval_id: ''})

    const loadPay=()=>{
        return new Promise(function(resolve){
            resolve(
                requestApi("pay/load",{
                        context:context,
                        reference:reference,
                        user_id:savedUserId,
                        cashier:globalContext.user_id
                    })
                    .then(data=>{
                        return data
                    })
            )
        })
    }

    useEffect(()=> {
        if (success == 1) {
            new Audio('/supplies/payment_success_sound.mp3').play();
        };
    }, [success]);

    useEffect(()=>{
        paymentLoading("show")
        Promise.all([loadPay].map(func=>func())).then((result)=>{
            if(result[0]["status"]=="success"){
                setFail(false)
                setPayment(result[0]["payment"])
                setAmount(formatAmount(result[0]["payment"]["amount"],result[0]["payment"]["currency"],"components"))
                setStage("user")
            }else{
                setFail(true)
            }
            paymentLoading("hide")
        })
    },[])

    const paymentLoader=useRef()
    let loadingTimer
    const paymentLoading=(action)=>{
        if(loadingTimer){
            clearTimeout(loadingTimer)
        }
        if(action=="show"){
            paymentLoader.current.style.display="flex"
            loadingTimer=setTimeout(()=>{
                paymentLoader.current.classList.add("payment-loading-shown")
            },0)
        }else if(action=="hide"){
            paymentLoader.current.classList.remove("payment-loading-shown")
            loadingTimer=setTimeout(()=>{
                paymentLoader.current.style.display="none"
            },500)
        }
    }

    const setUser=()=>{
        if(payment["user"]=="" || userChange==true){
            if(!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,7})+$/.test(emailVal.trim())){
                emailInput.current.focus()
            }else{
                paymentLoading("show")
                requestApi("pay/user",{
                        email:emailVal,
                        point_id:payment["point"]["id"]
                    })
                    .then(data=>{
                        paymentLoading("hide")
                        if(data["status"]=="user_exists"){
                            let paymentNew={...payment}
                            paymentNew["user"]=data["user"]
                            paymentNew["user"]["os_name"]=osName
                            paymentNew["user"]["os_version"]=osVersion
                            paymentNew["user"]["browser_name"]=browserName
                            paymentNew["user"]["browser_version"]=browserVersion
                            paymentNew["user"]["device_type"]=deviceType
                            paymentNew["user"]["device_vendor"]=mobileVendor
                            paymentNew["user"]["device_model"]=mobileModel
                            setPayment(paymentNew)
                            setUserChange(false)
                        }else if(data["status"]=="user_not_allowed"){
                            changeDialog("user_not_allowed","","")
                        }else if(data["status"]=="user_not_exists"){
                            let paymentNew={...payment}
                            paymentNew["user"]=""
                            setPayment(paymentNew)
                            setStage("personal")
                        }
                    })
            }
        }else{
            context=="qr"?setStage("amount"):setStage("methods")
        }
    }

    const newUser=()=>{
        if(firstnameVal==""){
            firstnameInput.current.focus()
        }else{
            if(lastnameVal==""){
                lastnameInput.current.focus()
            }else{
                if(dniVal==""){
                    dniInput.current.focus()
                }else{
                    let updateUser={
                        id:0,
                        email:emailVal,
                        first_name:firstnameVal,
                        last_name:lastnameVal,
                        capitals:"",
                        has_picture:0,
                        dni:dniVal,
                        tokens:[],
                        phone:"",
                        street:"",
                        numeration:"",
                        additional:"",
                        city:"",
                        region:"",
                        country:"",
                        postal_code:"",
                        latitude:"",
                        longitude:"",
                        place_id:"",
                        os_name:osName,
                        os_version:osVersion,
                        browser_name:browserName,
                        browser_version:browserVersion,
                        device_type:deviceType,
                        device_vendor:mobileVendor,
                        device_model:mobileModel
                    }
                    let paymentNew={...payment}
                    paymentNew["user"]=updateUser
                    setPayment(paymentNew)
                    context=="qr"?setStage("amount"):setStage("methods")
                }
            }
        }
    }

    const setQr=()=>{
        if(qrAmountVal==""){
            qrAmountInput.current.focus()
        }else{
            let newAmount=parseFloat(qrAmountVal.replace(/,/g,"."))
            if(newAmount==0){
                qrAmountInput.current.focus()
            }else{
                let paymentNew={...payment}
                paymentNew["amount"]=newAmount
                if(qrDetailVal!=""){
                    paymentNew["items"][0]["name"]=qrDetailVal
                }
                setPayment(paymentNew)
                setAmount(formatAmount(newAmount,payment["currency"],"components"))
                setStage("methods")
            }
        }
    }

    const back=(e)=>{
        if(stage=="user" && userChange==true){
            setUserChange(false)
        }
        if(stage=="personal"){
            setStage("user")
        }
        if(stage=="amount"){
            if(userChange==true){
                setStage("personal")
            }else{
                setStage("user")
            }
        }
        if(stage=="methods"){
            if(userChange==true){
                setStage("personal")
            }else{
                setStage("user")
            }
        }
        if(stage=="card"){
            setStage("methods")
        }
        if(stage=="debin"){
            setStage("methods")
        }

        if (stage === 'qrInteroperableTransfer' || stage === 'cashierQr') {
            setStage('methods')
            clearQrInteroperableData(qrInteroperableData.interval_id)
        }
    }

    const initPay=()=>{
        paymentLoading("show")
        payzenForm.current.style.display="none"
        requestApi("pay/form",{
            payment:payment
        })
        .then(data=>{
            if(data["status"]=="success"){
                KRGlue.loadLibrary("https://api.payzen.lat/",payment["point"]["payzen_public_key"])
                    .then(({KR})=>
                        KR.setFormConfig({
                            "formToken":data["form_token"],
                            "kr-language":"es-AR",
                            "kr-hide-debug-toolbar":true,
                            "kr-placeholder-pan":" ",
                            "kr-placeholder-expiry":" ",
                            "kr-placeholder-security-code":" ",
                            "kr-placeholder-identity-document-type":" ",
                            "kr-placeholder-identity-document-number":" ",
                            "kr-placeholder-installment-number":" ",
                            "kr-placeholder-installment-delay":" ",
                            "kr-placeholder-card-holder-mail":" ",
                            "kr-placeholder-card-holder-name":" ",
                            "kr-label-expiry":"Fecha de vencimiento",
                            "kr-label-pan":"Número de tarjeta",
                            "kr-label-security-code":"Código de seguridad",
                            "kr-label-identity-document-type":"Tipo de documento",
                            "kr-label-identity-document-number":"Número de documento",
                            "kr-label-installment-number":"Cantidad de cuotas",
                            "kr-label-installment-delay":"Pago en diferido",
                            "kr-label-card-holder-mail":"Correo electrónico",
                            "kr-label-card-holder-name":"Titular como aparece en la tarjeta"
                        })
                    )
                    .then(({KR})=>
                        KR.attachForm("#payzen-form")
                    )
                    .then(({KR,result})=>{
                            KR.showForm(result.formId)
                            KR.onFormCreated(()=>{})
                            KR.onFormReady(()=>{
                                payzenForm.current.style.display="block"
                                paymentLoading("hide")
                            })
                            KR.onBrandChanged(({KR,cardInfo})=>{
                                if(cardInfo.brand=="visa"){
                                    setSelectedCard({title:"Visa",icon_type:"card",icon_reference:"1"})
                                }else if(cardInfo.brand=="visa_debit"){
                                    setSelectedCard({title:"Visa Débito",icon_type:"card",icon_reference:"1"})
                                }else if(cardInfo.brand=="visa_electron"){
                                    setSelectedCard({title:"Visa Electron",icon_type:"card",icon_reference:"1"})
                                }else if(cardInfo.brand=="mastercard"){
                                    setSelectedCard({title:"Mastercard",icon_type:"card",icon_reference:"2"})
                                }else if(cardInfo.brand=="mastercad_debit"){
                                    setSelectedCard({title:"Mastercard Débito",icon_type:"card",icon_reference:"2"})
                                }else if(cardInfo.brand=="maestro"){
                                    setSelectedCard({title:"Maestro",icon_type:"card",icon_reference:"2"})
                                }else if(cardInfo.brand=="amex"){
                                    setSelectedCard({title:"American Express",icon_type:"card",icon_reference:"3"})
                                }else if(cardInfo.brand=="naranja"){
                                    setSelectedCard({title:"Naranja X",icon_type:"card",icon_reference:"4"})
                                }else if(cardInfo.brand=="cabal"){
                                    setSelectedCard({title:"Cabal",icon_type:"card",icon_reference:"5"})
                                }else if(cardInfo.brand=="cabal_debit"){
                                    setSelectedCard({title:"Cabal Débito",icon_type:"card",icon_reference:"5"})
                                }else if(cardInfo.brand=="diners"){
                                    setSelectedCard({title:"Diners",icon_type:"card",icon_reference:"6"})
                                }else if(cardInfo.brand=="cmr"){
                                    setSelectedCard({title:"CMR Falabella",icon_type:"card",icon_reference:"7"})
                                }else if(cardInfo.brand=="cb"){
                                    setSelectedCard({title:"Cabal",icon_type:"card",icon_reference:"5"})
                                }else{
                                    setSelectedCard({title:"Tarjeta de crédito o débito",icon_type:"new-card",icon_reference:""})
                                }
                            })
                            KR.onInstallmentChanged(({KR,installmentInfo})=>{})
                            KR.onFocus((event)=>{})
                            KR.onBlur((event)=>{})
                            KR.onFormValid(({KR,cardInfo})=>{})
                            KR.onSubmit(paymentData=>{})
                            KR.button.onClick(()=>{
                                paymentLoading("show")
                            })
                            KR.onError((event)=>{
                                paymentLoading("hide")
                                let errorCode=event.errorCode
                                let detailedErrorCode=event.detailedErrorCode
                                let errorProcessor=""
                                if(errorCode=="ACQ_001"){
                                    errorProcessor=event.metadata.answer.clientAnswer.transactions[0].transactionDetails.acquirerNetwork
                                }
                                if(event.children.length>1){
                                    errorCode=event.children[0].errorCode
                                    detailedErrorCode=event.children[0].detailedErrorCode
                                }
                                let explainedErrors=["PSP_003","PSP_091","PSP_625","PSP_624","PSP_042","PSP_099","PSP_108","PSP_649","PSP_536","PSP_641","PSP_647","PSP_607","PSP_601","PSP_671","ACQ_001","ACQ_999"]
                                if(explainedErrors.includes(errorCode)){
                                    let errorTitle="Pago rechazado."
                                    let errorDetail=""
                                    if(errorCode=="PSP_003" || errorCode=="PSP_091" || errorCode=="PSP_625" || errorCode=="PSP_624"){
                                        errorDetail="El medio de pago fue rechazado, intentá con otra tarjeta."
                                    }else if(errorCode=="PSP_042"){
                                        errorDetail="El saldo de la tarjeta no es suficiente."
                                    }else if(errorCode=="PSP_099"){
                                        errorDetail="Demasiados intentos, probá nuevamente en algunos minutos."
                                    }else if(errorCode=="PSP_108" || errorCode=="PSP_649"){
                                        errorDetail="El formulario de pago ha expirado, recargá la página para intentar nuevamente."
                                    }else if(errorCode=="PSP_536" || errorCode=="PSP_641" || errorCode=="PSP_647"){
                                        errorDetail="El analizador de riesgos rechazó esta transacción."
                                    }else if(errorCode=="PSP_607"){
                                        errorDetail="La tarjeta guardada ya no es válida."
                                    }else if(errorCode=="PSP_601" || errorCode=="PSP_671"){
                                        errorDetail="Ocurrió un problema con las cuotas elegidas."
                                    }else if(errorCode=="ACQ_999"){
                                        errorDetail="Ocurrió un error técnico, reiniciá el pago para intentar otra vez."
                                    }else if(errorCode=="ACQ_001"){
                                        if(errorProcessor=="FIRSTDATA_IPG"){
                                            if(detailedErrorCode=="0" || detailedErrorCode=="11" || detailedErrorCode=="85"){
                                                errorDetail="Transacción aprobada."
                                            }else if(detailedErrorCode=="1" || detailedErrorCode=="2"){
                                                errorDetail="Comunicate con el banco emisor para pedir que autoricen el pago."
                                            }else if(detailedErrorCode=="4" || detailedErrorCode=="7" || detailedErrorCode=="43"){
                                                errorDetail="Posible fraude, se recomienda retener la tarjeta."
                                            }else if(detailedErrorCode=="5"){
                                                errorDetail="La transacción ha sido denegada."
                                            }else if(detailedErrorCode=="12"){
                                                errorDetail="La transacción resultó inválida."
                                            }else if(detailedErrorCode=="13"){
                                                errorDetail="El monto de la operación no es válido."
                                            }else if(detailedErrorCode=="14" || detailedErrorCode=="46"){
                                                errorDetail="La tarjeta no es válida, revisá los datos ingresados o intentá con otra."
                                            }else if(detailedErrorCode=="30"){
                                                errorDetail="El número de tarjeta ingresado es incorrecto."
                                            }else if(detailedErrorCode=="38" || detailedErrorCode=="55"){
                                                errorDetail="El PIN de seguridad ingresado es incorrecto."
                                            }else if(detailedErrorCode=="45"){
                                                errorDetail="La tarjeta no permite operar en cuotas."
                                            }else if(detailedErrorCode=="47"){
                                                errorDetail="Se requiere el PIN de seguridad."
                                            }else if(detailedErrorCode=="48"){
                                                errorDetail="Se superó la cantidad máxima de cuotas permitidas."
                                            }else if(detailedErrorCode=="49"){
                                                errorDetail="La fecha de vencimiento es incorrecta."
                                            }else if(detailedErrorCode=="51"){
                                                errorDetail="No hay fondos suficientes para realizar el pago."
                                            }else if(detailedErrorCode=="53"){
                                                errorDetail="No existe una cuenta asociada."
                                            }else if(detailedErrorCode=="54"){
                                                errorDetail="La tarjeta está vencida."
                                            }else if(detailedErrorCode=="56"){
                                                errorDetail="La tarjeta no está autorizada."
                                            }else if(detailedErrorCode=="57"){
                                                errorDetail="La transacción no está permitida."
                                            }else if(detailedErrorCode=="61" || detailedErrorCode=="65"){
                                                errorDetail="El monto del pago supera el límite disponible de la tarjeta."
                                            }else if(detailedErrorCode=="76" || detailedErrorCode=="91"){
                                                errorDetail="Ocurrió un error al procesar la llamada."
                                            }else if(detailedErrorCode=="77"){
                                                errorDetail="Ocurrió un error al procesar las cuotas."
                                            }else if(detailedErrorCode=="89"){
                                                errorDetail="La terminal no está habilitada."
                                            }else{
                                                errorDetail="Revisá los datos que ingresaste de la tarjeta, verificá que tenga fondos disponibles o probá con una tarjeta diferente."
                                            }
                                        }else if(errorProcessor=="PRISMA"){
                                            if(detailedErrorCode=="0" || detailedErrorCode=="11" || detailedErrorCode=="85"){
                                                errorDetail="Transacción aprobada."
                                            }else if(detailedErrorCode=="1" || detailedErrorCode=="2" || detailedErrorCode=="76" || detailedErrorCode=="91" || detailedErrorCode=="7"){
                                                errorDetail="Comunicate con el banco emisor para pedir que autoricen el pago."
                                            }else if(detailedErrorCode=="4" || detailedErrorCode=="43"){
                                                errorDetail="Posible fraude, se recomienda retener la tarjeta."
                                            }else if(detailedErrorCode=="5" || detailedErrorCode=="14" || detailedErrorCode=="25"){
                                                errorDetail="La tarjeta ha sido denegada."
                                            }else if(detailedErrorCode=="12"){
                                                errorDetail="La transacción no pudo ser reconocida."
                                            }else if(detailedErrorCode=="13"){
                                                errorDetail="El monto de la operación no es válido."
                                            }else if(detailedErrorCode=="28" || detailedErrorCode=="102" || detailedErrorCode=="404"){
                                                errorDetail="Momentáneamente el servicio no está disponible, reintentá en unos segundos."
                                            }else if(detailedErrorCode=="30"){
                                                errorDetail="Ocurrió un error relacionado al formato del mensaje."
                                            }else if(detailedErrorCode=="38"){
                                                errorDetail="Se superaron los intentos permitidos para ingresar el PIN de seguridad."
                                            }else if(detailedErrorCode=="45"){
                                                errorDetail="La tarjeta no permite operar en cuotas."
                                            }else if(detailedErrorCode=="46"){
                                                errorDetail="La tarjeta aún no se encuentra vigente."
                                            }else if(detailedErrorCode=="47"){
                                                errorDetail="Se requiere el PIN de seguridad."
                                            }else if(detailedErrorCode=="48"){
                                                errorDetail="Se superó la cantidad máxima de cuotas permitidas."
                                            }else if(detailedErrorCode=="49"){
                                                errorDetail="La fecha de vencimiento es incorrecta."
                                            }else if(detailedErrorCode=="51"){
                                                errorDetail="No hay fondos suficientes para realizar el pago."
                                            }else if(detailedErrorCode=="53"){
                                                errorDetail="No existe una cuenta asociada."
                                            }else if(detailedErrorCode=="54"){
                                                errorDetail="La tarjeta está vencida."
                                            }else if(detailedErrorCode=="55"){
                                                errorDetail="El código de identificación personal es incorrecto."
                                            }else if(detailedErrorCode=="61" || detailedErrorCode=="65"){
                                                errorDetail="El monto del pago supera el límite disponible de la tarjeta."
                                            }else if(detailedErrorCode=="77"){
                                                errorDetail="La cantidad de cuotas no es válida para el plan seleccionado."
                                            }else if(detailedErrorCode=="89"){
                                                errorDetail="La terminal no está habilitada."
                                            }else if(detailedErrorCode=="998"){
                                                errorDetail="Lote cerrado, intentá un reembolso."
                                            }else{
                                                errorDetail="Revisá los datos que ingresaste de la tarjeta, verificá que tenga fondos disponibles o probá con una tarjeta diferente."
                                            }
                                        }else if(errorProcessor=="AMEX_GLOBAL"){
                                            if(detailedErrorCode=="000" || detailedErrorCode=="001"){
                                                errorDetail="Transacción aprobada."
                                            }else if(detailedErrorCode=="002"){
                                                errorDetail="La operación con tarjeta prepaga se autorizó parcialmente."
                                            }else if(detailedErrorCode=="100"){
                                                errorDetail="La transacción ha sido denegada."
                                            }else if(detailedErrorCode=="101"){
                                                errorDetail="La tarjeta está vencida o la fecha de vencimiento es incorrecta."
                                            }else if(detailedErrorCode=="106"){
                                                errorDetail="Se superaron los intentos permitidos para ingresar el PIN de seguridad."
                                            }else if(detailedErrorCode=="107"){
                                                errorDetail="Comunicate con el banco emisor para pedir que autoricen el pago."
                                            }else if(detailedErrorCode=="109"){
                                                errorDetail="El comerciante no es válido."
                                            }else if(detailedErrorCode=="110"){
                                                errorDetail="El monto de la operación no es válido."
                                            }else if(detailedErrorCode=="117" || detailedErrorCode=="122"){
                                                errorDetail="El PIN de seguridad ingresado es incorrecto."
                                            }else if(detailedErrorCode=="119"){
                                                errorDetail="El titular de la tarjeta no está registrado o no tiene permiso."
                                            }else if(detailedErrorCode=="183"){
                                                errorDetail="La divisa seleccionada no es válida."
                                            }else if(detailedErrorCode=="187"){
                                                errorDetail="Transacción rechazada, debés utilizar la nueva tarjeta emitida."
                                            }else if(detailedErrorCode=="189"){
                                                errorDetail="Transacción rechazada, la cuenta está anulada."
                                            }else if(detailedErrorCode=="200"){
                                                errorDetail="Transacción rechazada, retirá la tarjeta."
                                            }else{
                                                errorDetail="Revisá los datos que ingresaste de la tarjeta, verificá que tenga fondos disponibles o probá con una tarjeta diferente."
                                            }
                                        }
                                    }
                                    setError({
                                        errorCode:errorCode,
                                        detailedErrorCode:detailedErrorCode,
                                        title:errorTitle,
                                        detail:errorDetail
                                    })
                                }else{
                                    changeToast("pay_error",{errorCode:errorCode,detailedErrorCode:detailedErrorCode})
                                }
                            })
                            KR.onTransactionCreated((event)=>{
                                //Get transaction id
                                requestApi("pay/transaction_id",{
                                        uuid:event.clientAnswer.transactions[0].uuid
                                    })
                                    .then(data=>{
                                        setTransactionId(data["transaction_id"])
                                        setSavedUserId(data["user_id"])
                                    })
                                paymentLoading("hide")
                                if(event.clientAnswer.orderStatus=="PAID"){
                                    if(payment.url_success!=""){
                                        setReturning(payment.url_success)
                                    }
                                    setSuccess(1)
                                    if(event.clientAnswer.transactions[0].detailedStatus=="ACCEPTED"){
                                    }else if(event.clientAnswer.transactions[0].detailedStatus=="AUTHORISED"){
                                    }else if(event.clientAnswer.transactions[0].detailedStatus=="CAPTURED"){
                                    }else if(event.clientAnswer.transactions[0].detailedStatus=="PARTIALLY_AUTHORISED"){
                                    }
                                }else if(event.clientAnswer.orderStatus=="PARTIALLY_PAID"){
                                    if(payment.url_pending!=""){
                                        setReturning(payment.url_pending)
                                    }
                                }else if(event.clientAnswer.orderStatus=="RUNNING"){
                                    if(payment.url_pending!=""){
                                        setReturning(payment.url_pending)
                                    }
                                    if(event.clientAnswer.transactions[0].detailedStatus=="AUTHORISED_TO_VALIDATE"){
                                    }else if(event.clientAnswer.transactions[0].detailedStatus=="WAITING_AUTHORISATION"){
                                    }else if(event.clientAnswer.transactions[0].detailedStatus=="WAITING_AUTHORISATION_TO_VALIDATE"){
                                    }
                                }else if(event.clientAnswer.orderStatus=="UNPAID"){
                                    if(payment.url_failure!=""){
                                        setReturning(payment.url_failure)
                                    }
                                    if(event.clientAnswer.transactions[0].detailedStatus=="REFUSED"){
                                    }else if(event.clientAnswer.transactions[0].detailedStatus=="ERROR"){
                                    }else if(event.clientAnswer.transactions[0].detailedStatus=="CANCELLED"){
                                    }else if(event.clientAnswer.transactions[0].detailedStatus=="EXPIRED"){
                                    }
                                }else if(event.clientAnswer.orderStatus=="ABANDONED"){
                                    if(payment.url_failure!=""){
                                        setReturning(payment.url_failure)
                                    }
                                }
                            })
                        }
                    )
                    .catch((error)=>{
                            errorForm()
                            requestApi("general/bugs_tracker",{title:"KRGlue.loadLibrary (client)",content:error}).then(data=>{})
                        }
                    )
            }else{
                errorForm()
                requestApi("general/bugs_tracker",{title:data["status"]+" (client)",content:data}).then(data=>{})
            }
        })
    }

    const newCard=()=>{
        let paymentNew={...payment}
        paymentNew["payment_token"]=""
        setPayment(paymentNew)
        setStage("card")
    }

    const paymentToken=(token)=>{
        let paymentNew={...payment}
        paymentNew["payment_token"]=token
        setPayment(paymentNew)
        setStage("card")
    }

    useEffect(()=>{
        if(transactionId!=0){
            if(returning!=""){
                if(returning.includes("?")){
                    setReturning(returning+"&id="+transactionId)
                }else{
                    setReturning(returning+"?id="+transactionId)
                }
            }
        }
    },[transactionId])

    useEffect(()=>{
        localStorage.setItem("payment_user_id",savedUserId)
    },[savedUserId])

    const errorForm=()=>{
        changeDialog("generic_error","","")
        back()
        paymentLoading("hide")
    }

    const retry=()=>{
        setError("")
        setReturning("")
    }

    const redirect=()=>{
        window.location.href=returning
    }

    const downloadReceipt=(event,transaction)=>{
        event.preventDefault()
        let link=document.createElement("a")
        link.setAttribute("download","")
        link.href=globalContext["base_url"]+"api/plain/receipt/"+transaction
        document.body.appendChild(link)
        link.click()
        link.remove()
    }

    const showSuccessStage = (transactionUuid) => {
        requestApi("pay/transaction_id", {
            uuid: transactionUuid
        })
        .then(data => {
            setTransactionId(data["transaction_id"])
            setSavedUserId(data["user_id"])

            paymentLoading("hide")
            setSuccess(1)
        })
    }

    const checkQrOrderStatus = (orderId) => {
        let attempts = 0;
        let intervalId = setInterval(function () {
            requestApi("qr-interoperable/get-qr-status", {
                order_id: orderId
            }).then(data => {
                attempts++
                if (data.paid === true) {
                    clearInterval(intervalId)
                    showSuccessStage(orderId)
                } else if (attempts > 200) {
                    clearInterval(intervalId)
                }
            })
        }, 3000)

        return intervalId;
    }

    const getQrInteroperable = () => {
        paymentLoading("show")
        requestApi("qr-interoperable/get-qr",{
            payment: payment
        }).then(data => {
            setQrInteroperableData(data)
            paymentLoading("hide")
        })
    }

    const createOrderForCashierQr = () => {
        paymentLoading("show")
        requestApi("qr-interoperable/user-qr-order",{
            payment: payment
        }).then(data => {
            data.encoded_qr = ''
            setQrInteroperableData(data)
            paymentLoading("hide")
        })
    }

    const clearQrInteroperableData = (intervalId) => {
        clearInterval(intervalId)

        setQrInteroperableData({encoded_qr: '', order_id: '', interval_id: ''})
    }

    useEffect(()=> {
        if (stage === "card") {
            initPay()
        }

        if (stage === "qrInteroperableTransfer") {
            getQrInteroperable()
        }

        if (stage === "cashierQr") {
            createOrderForCashierQr()
        }
    },[stage])

    useEffect(() => {
        if (qrInteroperableData.order_id !== '') {
            qrInteroperableData.interval_id = checkQrOrderStatus(qrInteroperableData.order_id)
            setQrInteroperableData(qrInteroperableData)

            return () => clearQrInteroperableData(qrInteroperableData.interval_id)
        }

    },[qrInteroperableData])

    const debin=()=>{
        if(cbuVal==""){
            cbuInput.current.focus()
            changeToast("cbu_empty","")
        }else{
            if(cuitVal==""){
                cuitInput.current.focus()
                changeToast("cuit_empty","")
            }else{
                if(cuitVal.length<11){
                    cuitInput.current.focus()
                    changeToast("cuit_invalid","")
                }else{
                    paymentLoading("show")
                    let cbuValue=""
                    let aliasValue=""
                    if(cbuVal.length==22 && /^\d+$/.test(cbuVal)){
                        cbuValue=cbuVal
                    }else{
                        aliasValue=cbuVal
                    }
                    requestApi("pay/debin_create",{
                            payment:payment,
                            cbu:cbuValue,
                            alias:aliasValue,
                            cuit:cuitVal
                        })
                        .then(data=>{
                            if(data["status"]=="success"){
                                if(payment.url_pending!=""){
                                    setReturning(payment.url_pending)
                                }
                                setSuccess(2)
                                paymentLoading("hide")
                            }else{
                                paymentLoading("hide")
                                changeToast("debin_failed","")
                            }
                        })
                }
            }
        }
    }

    const forgetCard=(reference,index)=>{
        requestApi("payments/token_remove",{
                payment_token_id:reference
            })
            .then(data=>{
                if(data.status=="success"){
                    let paymentsToken=payment["user"]["tokens"]
                    paymentsToken.splice(index,1)
                    let paymentNew={...payment}
                    paymentNew["user"]["tokens"]=paymentsToken
                    setPayment(paymentNew)
                    manageDialog("hide")
                }else if(data.status=="failed"){
                    changeDialog("generic_error","","")
                }
            })
    }

    return(
        <Fragment>

            <div className="payment noselect">

                {success=="" &&
                <div className="payment-header">
                    <div className="payment-brand">
                        <img src={brandImg} />
                    </div>
                    {(stage=="personal" || stage=="amount" || stage=="methods" || stage=="card" || stage=="debin" || stage === 'qrInteroperableTransfer' || userChange==true) &&
                    <div className="payment-back" onClick={back}>
                        <img src={backImg} />
                    </div>}
                </div>}

                <div className="payment-content">
                    {fail?
                    <div className="payment-fail">
                        <img className="payment-fail-picture" src={failImg} />
                        <div className="payment-fail-caption">Algo no anduvo bien...</div>
                        <div className="payment-fail-detail">No es posible realizar el pago en este momento.</div>
                    </div>:
                    <Fragment>
                        {payment?
                        <Fragment>

                            {success==""?
                            <Fragment>

                                <div className="payment-info">
                                    <div className="payment-icon">
                                        <Avatar element="shop" context="payment-shop" id={payment["shop"]["id"]} picture={payment["shop"]["has_picture"]} color="" capitals="" />
                                    </div>
                                    <div className="payment-detail">
                                        {payment["items"].length==1 &&
                                        <div className="payment-single">
                                            <div className="payment-single-detail">
                                                <div className="payment-shop">{payment["shop"]["name"]}</div>
                                                {payment["items"].map((item,i)=>{
                                                    return(
                                                        <div key={i} className="payment-item-name">{item["name"]}</div>
                                                    )
                                                })}
                                            </div>
                                            <div className="payment-single-amount">
                                                <div className="payment-total-amount">
                                                    {(context=="qr" && (stage=="user" || stage=="personal" || stage=="amount"))?
                                                    <Fragment></Fragment>
                                                    :<Fragment>
                                                        {amount["currencySymbol"]}{amount["integerPart"]}<span className="payment-decimals">{amount["decimalPart"]}</span>
                                                    </Fragment>}
                                                </div>
                                            </div>
                                        </div>}
                                        {payment.items.length>1 &&
                                        <div>
                                            <div className="payment-shop">{payment["shop"]["name"]}</div>
                                            <div className="payment-items">
                                                {payment.items.map((item,i)=>{
                                                    let itemAmount=formatAmount(item["amount"],payment["currency"],"components")
                                                    return (
                                                        <div className="payment-item" key={i}>
                                                            <div className="payment-item-name">{item["name"]}</div>
                                                            <div className="payment-item-amount">
                                                                {itemAmount["currencySymbol"]}{itemAmount["integerPart"]}<span className="payment-decimals">{itemAmount["decimalPart"]}</span>
                                                            </div>
                                                        </div>
                                                    )
                                                })}
                                                <div className="payment-total">
                                                    <div className="payment-total-label">Total:</div>
                                                    <div className="payment-total-amount">{amount["currencySymbol"]}{amount["integerPart"]}<span className="payment-decimals">{amount["decimalPart"]}</span></div>
                                                </div>
                                            </div>
                                        </div>}
                                    </div>
                                </div>

                                {stage=="user" &&
                                <div className="payment-block">
                                    {(payment["user"]=="" || userChange==true)?
                                    <Fragment>
                                        <div className="payment-caption">{context=="webpos"?"Escribí el email del cliente:":"Escribí tu email:"}</div>
                                        <PaymentTextbox ref={emailInput} type="text" limit={128} label="Correo electrónico" val={emailVal} setVal={setEmailVal} disabled={false} />
                                    </Fragment>
                                    :<Fragment>
                                        <div className="payment-user">
                                            <div className="payment-caption">{context=="webpos"?"Va a pagar como:":"Vas a pagar como:"}</div>
                                            <div className="payment-user-info">
                                                <div className="payment-user-avatar">
                                                    <Avatar element="user" context="xbig" id={payment["user"]["id"]} picture={payment["user"]["has_picture"]} color="" capitals={payment["user"]["capitals"]} />
                                                </div>
                                                <div className="payment-user-name">{payment["user"]["first_name"]+" "+payment["user"]["last_name"]}</div>
                                                <div>{payment["user"]["email"]}</div>
                                                <div>(DNI {parseInt(payment["user"]["dni"]).toLocaleString("es-AR")})</div>
                                                <div className="payment-user-change" onClick={()=>{setUserChange(true)}}>Cambiar</div>
                                            </div>
                                        </div>
                                    </Fragment>}
                                    <PaymentButton label="Continuar" symbol="next" clickEvent={setUser} />
                                </div>}

                                {stage=="personal" &&
                                <div className="payment-block">
                                    <div className="payment-caption">Completá estos datos:</div>
                                    <PaymentTextbox ref={firstnameInput} type="text" limit={64} label="Nombre" val={firstnameVal} setVal={setFirstnameVal} disabled={false} />
                                    <PaymentTextbox ref={lastnameInput} type="text" limit={64} label="Apellido" val={lastnameVal} setVal={setLastnameVal} disabled={false} />
                                    <PaymentTextbox ref={dniInput} type="text" mode="number" limit={8} label="Número de documento" val={dniVal} setVal={setDniVal} disabled={false} />
                                    <PaymentButton label="Continuar" symbol="next" clickEvent={newUser} />
                                </div>}

                                {stage=="amount" &&
                                <div className="payment-block">
                                    <div>
                                        <div className="payment-caption">¿Cuánto querés pagar con QR?</div>
                                        <PaymentTextbox ref={qrAmountInput} type="text" mode="currency" limit="" label="Monto a pagar" val={qrAmountVal} setVal={setQrAmountVal} disabled={false} />
                                    </div>
                                    <div>
                                        <div className="payment-caption">¿Qué estás pagando?</div>
                                        <PaymentTextbox ref={qrDetailInput} type="text" mode="" limit={255} label="Detalle (Opcional)" val={qrDetailVal} setVal={setQrDetailVal} disabled={false} />
                                    </div>
                                    <PaymentButton label="Continuar" symbol="next" clickEvent={setQr} />
                                </div>}

                                {stage=="methods" &&
                                <div className="payment-block">
                                    <div className="payment-caption">{context=="webpos"?"¿Cómo va a pagar?":"¿Cómo vas a pagar?"}</div>
                                    <div className="payment-methods">
                                        {payment["user"]["tokens"].map((item,i)=>{
                                            return(
                                                <PaymentItem key={i} name={item.card_name+" ****"+item.card_numbers} detail={<span className="payment-method-link" onClick={(event)=>{event.stopPropagation();changeDialog("forget_card","",()=>{forgetCard(item.id,i)})}}>Olvidar tarjeta</span>} type="card" reference={item.has_picture==1?item.card_id:0} clickEvent={()=>{paymentToken(item.token)}} />
                                            ) 
                                        })}
                                        {payment["cards"].length>0 &&
                                        <PaymentItem name="Nueva tarjeta" detail="Crédito, débito o prepaga" type="new-card" clickEvent={()=>{newCard()}} />}
                                        {payment["payments"].find(x=>x.class==="debin") &&
                                        <PaymentItem name="Transferencia bancaria" detail="Debin" type="debin" clickEvent={()=>{setStage("debin")}} />}
                                        {payment["payments"].find(x=>x.class==="qrInteroperableTransfer") &&
                                        <PaymentItem name="Transferencia bancaria con QR" detail="Desde tu billetera virtual" type="qr" clickEvent={()=>{setStage("qrInteroperableTransfer")}} />}
                                        {payment["cashierHasQr"] && <PaymentItem name="Cobrá con el QR de tu caja" detail="Desde cualquier billetera virtual" type="qr" clickEvent={()=>{setStage("cashierQr")}} />}
                                    </div>
                                    <div className="payment-secure-frame"> 
                                        <div className="payment-secure">
                                            <div className="payment-secure-icon"><img src={secureImg} /></div>
                                            <div className="payment-secure-caption">Pago seguro con Cajero24</div>
                                        </div>
                                    </div>
                                </div>}

                                {stage=="card" &&
                                <Fragment>
                                    <div className="payzen-form">
                                        <div ref={payzenForm} className="payzen-form-content">
                                            <div className="payment-block">
                                                {payment["payment_token"]==""?
                                                <Fragment>
                                                    <div className="payment-caption payment-caption-3">{selectedCard["title"]}</div>
                                                    <div className="payments-cards">
                                                        {payment["cards"].map((item,i)=>{
                                                            return(
                                                                <PaymentIcon key={i} context="" type="card" reference={item.has_picture==1?item.id:0} status={selectedCard["icon_reference"]==item.id?"on":"off"} />
                                                            ) 
                                                        })}
                                                    </div>
                                                </Fragment>:
                                                <Fragment>
                                                    <div className="payment-caption">Ingresá el código de seguridad:</div>
                                                </Fragment>}
                                                <div id="payzen-form">
                                                    <div className="kr-embedded">
                                                        <div className="kr-pan"></div>
                                                        <div className="kr-expiry"></div>
                                                        <div className="payment-cvv-frame">
                                                            <div className="kr-security-code"></div>
                                                            <div className="payment-cvv"><img src={cvvImg} /></div>
                                                        </div>
                                                        <div className="kr-installment-number"></div>
                                                        <button className="kr-payment-button"></button>
                                                        <div className="payment-secure-frame payment-secure-frame-2">
                                                            <div className="payment-secure">
                                                                <div className="payment-secure-icon"><img src={secureImg} /></div>
                                                                <div className="payment-secure-caption">Pago seguro con Cajero24</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Fragment>}

                                {stage=="debin" &&
                                <Fragment>
                                    <div className="payment-block">
                                        <PaymentIcon context="nav" type="debin" reference="" />
                                        <div className="payment-caption payment-caption-2">Transferencia bancaria</div>
                                        <div className="payment-caption-small">
                                            {context === "webpos" ? (
                                                <div dangerouslySetInnerHTML={{__html: "Va a pagar con <strong>Debin</strong>, deberá aprobar la operación desde la app de su banco o home banking."}}></div>
                                            ) : (
                                                <Fragment>
                                                    <div dangerouslySetInnerHTML={{__html: "Usá este medio de pago para generarte un Debin que debes aceptar desde el homebanking, a nombre de Clif SAS - Cajero24."}}></div>
                                                    <div dangerouslySetInnerHTML={{__html: "Si quisieras abonar con billeteras virtuales como Mercado Pago, Modo u otra, volvé un paso atrás y elegí pagar con QR."}}></div>
                                                </Fragment>
                                            )}
                                        </div>
                                        <PaymentTextbox ref={cbuInput} type="text" mode="" limit={255} label="CBU o alias de la cuenta bancaria" val={cbuVal} setVal={setCbuVal} disabled={false} />
                                        <PaymentTextbox ref={cuitInput} type="text" mode="number" limit={11} label="CUIT del/la titular de la cuenta" val={cuitVal} setVal={setCuitVal} disabled={false} />
                                        <PaymentButton label="Generar Debin" symbol="secure" clickEvent={debin} />
                                        <div className="payment-secure-frame payment-secure-frame-2"> 
                                            <div className="payment-secure">
                                                <div className="payment-secure-icon"><img src={secureImg} /></div>
                                                <div className="payment-secure-caption">Pago seguro con Cajero24</div>
                                            </div>
                                        </div>
                                    </div>
                                </Fragment>}
                                {stage=="qrInteroperableTransfer"&&
                                    <Fragment>
                                        <div className="payment-block">
                                            <PaymentIcon context="nav" type="qr" reference=""/>
                                            <div className="payment-caption payment-caption-2">Transferencia bancaria con QR</div>
                                            <div className="payment-caption-small">
                                                <div>Escanea el QR con cualquier billetera, y sigue las instrucciones en pantalla para pagar</div>
                                            </div>
                                            <div className="payment-secure-frame payment-secure-frame-2">
                                                <img className="qr-img" src={qrInteroperableData.encoded_qr}/>
                                                <div className="payment-secure">
                                                    <div className="payment-secure-icon"><img src={secureImg}/></div>
                                                    <div className="payment-secure-caption">Pago seguro con Cajero24
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Fragment>}
                                {stage=="cashierQr" &&
                                    <Fragment>
                                        <div className="payment-block">
                                            <PaymentIcon context="nav" type="qr" reference=""/>
                                            <div className="payment-caption payment-caption-2">Transferencia bancaria con QR</div>
                                            <div className="payment-caption-small">
                                                <div>Pedile al cliente que escanee el QR de tu caja con cualquier billetera virtual y siga las instrucciones para pagar</div>
                                            </div>
                                            <div className="payment-secure-frame payment-secure-frame-2">
                                                <div className="payment-secure">
                                                    <div className="payment-secure-icon"><img src={secureImg}/></div>
                                                    <div className="payment-secure-caption">Pago seguro con Cajero24
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Fragment>}
                            </Fragment> :

                                <div className="payment-success">
                                    <div className="payment-success-content">
                                        <div className="payment-success-brand"><img src={brand}/></div>
                                        <div className="payment-success-circle">
                                            <div className="payment-success-sparkles"><img src={sparkles}/></div>
                                            <div className="payment-success-avatar">
                                                <Avatar element="shop" context="payment-success"
                                                        id={payment["shop"]["id"]}
                                                        picture={payment["shop"]["has_picture"]} color="" capitals=""/>
                                            </div>
                                        </div>
                                        <div className="payment-success-caption">
                                            {success == 1 &&
                                                <Fragment>
                                                    ¡{context == "webpos" ? "Pagó" : "Pagaste"} <span
                                                    className="payment-success-amount">{amount["currencySymbol"]}{amount["integerPart"]}{amount["decimalPart"]}</span> a {payment["shop"]["name"]}!
                                                </Fragment>
                                            }
                                            {success == 2 &&
                                                <Fragment>
                                                    <div className="payment-pending">Pago pendiente</div>
                                                    <div className="payment-success-caption-alt">
                                                        Has generado una solicitud de Debin por <span className="payment-success-amount">{amount["currencySymbol"]}{amount["integerPart"]}{amount["decimalPart"]}</span> a nombre de <span className="payment-success-amount">Clif SAS</span>, controlante de <span className="payment-success-amount">Cajero24.</span> Una vez completado tu pago, será reenviado de inmediato a <span className="payment-success-amount">{payment["shop"]["name"]}.</span>
                                                    </div>
                                                    <div className="payment-success-caption-alt">
                                                        Por tu seguridad ingresá dentro de los <span className="payment-success-amount">próximos 30 minutos</span> a tu homebanking o app de tu banco, en "Debin", para aprobarlo y ejecutar la transferencia. Te llegará el recibo a tu e-mail.
                                                    </div>
                                                </Fragment>
                                            }
                                    </div>
                                    <div>
                                        {success==1 &&
                                        <div className="payment-success-button" onClick={(event)=>{downloadReceipt(event,transactionId)}}>
                                            <div><svg id="b" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15 15.2"><g id="c"><path d="m12.18,5.94c.25.22.27.6.05.85l-2.39,2.72c-.18.2-.43.32-.7.32-.27,0-.52-.11-.7-.32l-1.11-1.26c-.22-.25-.19-.63.06-.85.25-.22.63-.19.85.06l.91,1.03,2.19-2.49c.22-.25.6-.27.85-.05Zm-.13,6.27v.44c0,1.41-1.12,2.55-2.5,2.55H2.5c-1.38,0-2.5-1.15-2.5-2.55V2.55C0,1.15,1.12,0,2.5,0h7.05c1.38,0,2.5,1.15,2.5,2.55v.44c1.74.81,2.95,2.57,2.95,4.61s-1.21,3.8-2.95,4.61Zm-1.18.39c-.31.06-.64.1-.97.1-2.81,0-5.1-2.29-5.1-5.1s2.29-5.1,5.1-5.1c.33,0,.66.04.97.1v-.05c0-.75-.59-1.35-1.32-1.35H2.5c-.73,0-1.32.61-1.32,1.35v10.1c0,.75.59,1.35,1.32,1.35h7.05c.73,0,1.32-.61,1.32-1.35v-.05Zm2.93-5c0-2.15-1.75-3.9-3.9-3.9s-3.9,1.75-3.9,3.9,1.75,3.9,3.9,3.9,3.9-1.75,3.9-3.9Z" fill="#fff"/></g></svg></div>
                                            <div>Descargar comprobante</div>
                                        </div>}
                                        {(returning!="" && context!="webpos") &&
                                        <div className="payment-success-button-alt" onClick={redirect}>
                                            <div><svg id="b" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13.81 15.2"><g id="c"><path d="m13.81,9.49c0,3.15-2.56,5.71-5.71,5.71H1.25c-.33,0-.6-.27-.6-.6s.27-.6.6-.6h6.85c2.49,0,4.51-2.02,4.51-4.51s-2.02-4.51-4.51-4.51H2.26l3.27,2.72c.25.21.29.59.08.85-.12.14-.29.22-.46.22-.13,0-.27-.05-.38-.14L.22,4.84c-.14-.11-.22-.28-.22-.46s.08-.35.22-.46L4.77.14c.26-.21.63-.18.85.08.21.26.18.63-.08.85l-3.27,2.72h5.84c3.15,0,5.71,2.56,5.71,5.71Z" fill="#75e8f0"/></g></svg></div>
                                            <div>Retornar al sitio web</div>
                                        </div>}
                                        {context=="webpos" &&
                                        <Fragment>
                                            <div className="payment-success-button-alt" onClick={event}>
                                                <div>Hacer otro cobro</div>
                                            </div>
                                            <div className="payment-success-button-alt" onClick={()=>{navigate("/c24/actividad")}}>
                                                <div>Ver actividad</div>
                                            </div>
                                        </Fragment>}
                                    </div>
                                    {(returning!="" && context!="webpos") &&
                                    <div className="payment-success-returning">Retornando automáticamente en <Countdown date={Date.now()+20000} renderer={({seconds})=>{return <Fragment>{seconds}</Fragment>}} onComplete={()=>{redirect()}} /> segundos...</div>}
                                </div>
                            </div>}
                        </Fragment>:
                        <div className="payment-empty"></div>}
                    </Fragment>}
                </div>

                {error!="" &&
                <div className="payment-error">
                    <div className="payment-error-content">
                        <div className="payment-error-pic"><img src={fail2Img} /></div>
                        <div className="payment-error-title">{error.title}</div>
                        {error.detail!="" && <div className="payment-error-detail">{error.detail}</div>}
                        <div className="payment-error-button">
                            <div className="payment-error-retry" onClick={retry}>Reintentar</div>
                            {(returning!="" && context!="webpos") &&
                            <div className="payment-returning-button" onClick={redirect}>
                                <div><svg id="b" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13.81 15.2"><g id="c"><path d="m13.81,9.49c0,3.15-2.56,5.71-5.71,5.71H1.25c-.33,0-.6-.27-.6-.6s.27-.6.6-.6h6.85c2.49,0,4.51-2.02,4.51-4.51s-2.02-4.51-4.51-4.51H2.26l3.27,2.72c.25.21.29.59.08.85-.12.14-.29.22-.46.22-.13,0-.27-.05-.38-.14L.22,4.84c-.14-.11-.22-.28-.22-.46s.08-.35.22-.46L4.77.14c.26-.21.63-.18.85.08.21.26.18.63-.08.85l-3.27,2.72h5.84c3.15,0,5.71,2.56,5.71,5.71Z" fill="#FFFFFF"/></g></svg></div>
                                <div>Retornar al sitio web</div>
                            </div>}
                        </div>
                    </div>
                </div>}

                <div ref={paymentLoader} className="payment-loading">
                    <svg className="payment-spinner" viewBox="0 0 50 50">
                        <circle className="payment-spinner-path" cx="25" cy="25" r="20" fill="none" strokeWidth="3"></circle>
                    </svg>
                </div>

            </div>

            {(success=="" && context=="webpos") &&
            <div className="webpos-cancel noselect">
                <div onClick={event}>Cancelar este pago</div>
            </div>}

        </Fragment>
    )
    
}